import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { DatePicker } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { baseUrl } from "../../../services/config";
import Select from "react-select";
import MySelect from "../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { sendAddNotifications } from "../../../components/SendNotifications/SendNotifications";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const AddNotification = ({ open, close, campusData, type, reloader }) => {
  // const user = useSelector((state) => state.user.user);
  // const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  let authtoken = localStorage.getItem("userToken");
  const [isanysubject, setisanysubject] = useState(false);
  const [optionSelected, setoptionSelected] = useState([]);

  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };

  const handleAdd = () => {
    if (
      typeId == "" ||
      title == "" ||
      description == "" ||
      campusId.length == 0
    ) {
      toast.error("Please Enter All Fields");
      return;
    }

    setLoading(true);
    const data = {
      notification_type_id: typeId,
      title: title,
      description: description,
      campus_ids: campusId,
      start_date: typeId == "2" ? startDate : "",
      end_date: typeId == "2" ? endDate : "",
    };
    fetch(`${baseUrl}/notifications`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        getStudentIdz(campusId);
        setLoading(false);
        toast.success("Added Successfully");
        close();
        reloader();

        console.log(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  const getStudentIdz = (cmapusiddd) => {
    let notifaction_type_name = type
      .filter((item) => item.id == typeId)
      .map((item) => item.name);

    const data = {
      campus_ids: cmapusiddd,
    };
    fetch(`${baseUrl}/student-notification_ids`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        sendAddNotifications(
          title,
          description,
          data.payload,
          notifaction_type_name[0],
          typeId
        );
        setTitle("");
        setDescription("");
        setCampusId("");
        setoptionSelected([]);
        console.log(data, "get izzz");
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };
  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChangeSubjects = (selected) => {
    console.log(selected);
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setCampusId([...valuearray]);
    if (valuearray.length == 0) {
      setisanysubject(true);
      return;
    }
    setisanysubject(false);
  };

  return (
    <>
      <Toaster />
      <Modal size="lg" show={open}>
        <Modal.Header>
          <h3>Add New Notification</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <label>
                  <b>Select Campus</b>
                </label>
                <MySelect
                  options={campusData}
                  isMulti
                  name="selectedOptions"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{ Option, MultiValue, animatedComponents }}
                  onChange={handleChangeSubjects}
                  allowSelectAll={true}
                  value={optionSelected}
                />
              </div>
              <div className="col-sm-6">
                <label>
                  <b>Select Type</b>
                </label>
                <div className="d-flex flex-column">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setTypeId(e.target.value)}
                  >
                    <option selected value="">
                      {" "}
                      Select Type
                    </option>
                    {type &&
                      type.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label>
                  <b>Title</b>
                </label>
                <input
                  placeholder="Enter Title"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {typeId == "2" ? (
                <div className="col-md-6">
                  <labe>
                    <b>Select Start and End Date</b>
                  </labe>
                  <RangePicker
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    style={{ marginTop: "10px", height: "40px", width: "100%" }}
                    onChange={datePicker}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label>
                  <b>Description</b>
                </label>
                <textarea
                  placeholder="Enter Description..."
                  style={{ width: "100%" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => {
              setCampusId("");
              setoptionSelected([]);
              close();
            }}
            className="btn btn-danger w-100px"
          >
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={handleAdd}
            className="btn btn-primary w-100px"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNotification;
